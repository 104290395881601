
.h2style{
    color:white;
    font-size: 2rem;
    font-weight: lighter;
}

.linkstyle{     
    text-decoration: underline;
    text-underline-offset: 7px;
    font-size: 0.9em;
    font-weight: lighter; 
    line-height: 2.0;
    color:#fffffe;
     }

.linkstyle:hover{
        font-weight: bold;
      }

@media screen and (max-width:800px){
.projects-container {
    padding-top: 30px !important;
    margin-top: 0 !important;
    padding-bottom: 150px;
    
}
}