.main{
    
    display: block;
    background-color: #d4d8f0;
}

.title{
    font-weight: bolder;
    text-align: center;
}
.title h1{
    font-size: 2.3em;
    color: #232946;
}

.image{
    margin: 0 auto;
    margin-bottom: 50px;
}
.image img{
    border: 5px solid #b8c1ec;
    border-radius: 10px;
}

.description, .tech, .links {
    width:50%;
    margin: 0 auto;
    margin-bottom: 50px;
}
.description p, .tech p, .links p{
    color: #232946;
    font-size: 15px;
    font-weight: bold;
    line-height:2;
}


.links p{
    color: black;
    display: inline;
    padding-right: 15px;
    text-underline-offset: 2px;
}

.links p a{
   line-height:1.0;
   text-decoration: none;
   position: relative;
}

.links p a:hover{
    color:blue;
    font-weight:bold;
    
}

.links p a:after {
    content: '';

    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;

    border-width: 0 0 1px;
    border-style: solid;
}


@media screen and (max-width:800px){
    .main{
        margin:10px !important;
    }
    .title{
        text-align: left;
    }
    .title h1{
        font-size:1.8em;
        margin-left:50px;
    }
    .image{
        width: 100% !important;
        padding-left: 30px;
        padding-right:30px;
    }
    .description, .tech, .links{
        width:100%;
        padding: 0 40px;
    }
}