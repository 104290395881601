  
  .main-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .hero{
    width: 80%;
  }
  
  .projects{
    width:40%;
  }
  
  
  @media screen and (max-width:800px){
    .main-content{
      flex-direction: column;
    }
    .hero{
      width:100%;
      padding-left:0;
    }
    .projects{
      width:100%;
    }
  }
  