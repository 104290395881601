body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(rgb(26, 37, 55) 0px, rgb(54, 64, 81) 100%);*/
  background-color: /*#283344;*/ #232946;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');


p,h1 {
  color: #fffffe;
}

