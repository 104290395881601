

.welcome h1{
    font-size: 5rem;
    font-weight: lighter;
}

.text{
    margin-top: -30px;
}
.text span{
    color: #d1d1d1;
    font-size: 0.9rem;
    display: block;
    padding-bottom: 5px;
    line-height: 2.0;
    font-weight:lighter;
}


@media screen and (max-width:800px){
    .hero-header{
        padding-top: 20px !important;
        padding-left:5px !important;
        margin-left:5px !important;
        margin-top:5px !important;
      }

    .welcome h1{
        font-size:3rem;
        text-align: left;
        padding-left:50px;
        margin-bottom: 0 !important;
        padding-bottom:30px;
    }
    .text{
        padding-right: 15px !important;
        margin-top: 0;
        margin-right: 15px  !important;
    }
}